import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, switchMap, take, tap } from 'rxjs';
import {
    EnumBasketType,
    EnumPharmaPaymentType,
    IBasket,
    IBasketPayload,
    IBasketResponse,
    IBasketSessionCreateResponse,
    IBasketSessionDeleteResponse,
    IBasketSessionPaymentResponse,
    IListSealedItem,
} from 'app/layout/common/basket/basket.types';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { MatDialog } from '@angular/material/dialog';
import { ModalWarningComponent } from './modal-warning/modal-warning.component';
import * as moment from 'moment';
import { AlertService } from 'app/core/alerts/alert.service';
import { UserService } from 'app/core/user/user.service';
import { PusherService } from 'app/core/pusher/pusher.service';
import { subscribeToChannel } from 'app/core/global.helpers';
import { ExpepharmaTimerService } from 'app/core/services/timers/timers.service';
import {
    IHttpPharmaOfferStockRealtimeUpdated,
    IPharmaOfferStockRealtime,
} from 'app/core/pharma-offer/pharma-offer.types';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BasketService {
    private _items: ReplaySubject<IBasket[]> = new ReplaySubject<IBasket[]>(1);
    private _sessionHash: ReplaySubject<string> = new ReplaySubject<string>(
        null
    );
    private _sessionExpire: ReplaySubject<string> = new ReplaySubject<string>(
        null
    );
    // private timers = new Map<number, any>();
    private user_id: number;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _matDialog: MatDialog,
        private _alertService: AlertService,
        private _pusherService: PusherService,
        private _userService: UserService,
        private _expepharmaTimerService: ExpepharmaTimerService
    ) {
        this._userService.user$.pipe(take(1)).subscribe((e) => {
            this.user_id = e.id;
        });

        this._pusherService.channels$
            .pipe(
                subscribeToChannel(
                    'pharmaActionChannel',
                    'sessions_sealed',
                    (data: string) => {
                        const list: number[] = AuthUtils.fullAesDecode(data);
                        if (list.includes(this.user_id) === true) {
                            this._sessionHash.next(null);
                            this._sessionExpire.next(null);
                            // this.removeSealedSessionCheckout()
                            //     .pipe(take(1))
                            //     .subscribe();
                        }
                    }
                )
            )
            .subscribe();

        this._pusherService.channels$
            .pipe(
                subscribeToChannel(
                    'userChannel',
                    'remove_threshold',
                    (res: string) => {
                        const idBasket: number = AuthUtils.fullAesDecode(res);
                        this.removeItem(idBasket).pipe(take(1)).subscribe();
                    }
                )
            )
            .subscribe();

        this._pusherService.channels$
            .pipe(
                subscribeToChannel(
                    'pharmaActionChannel',
                    'update_stock',
                    (data: string) => {
                        const list: IPharmaOfferStockRealtime =
                            AuthUtils.fullAesDecode(data);
                        if (
                            list.user_id === null ||
                            list.user_id !== this.user_id
                        ) {
                            this.items$
                                .pipe(take(1))
                                .subscribe((items: IBasket[]) => {
                                    const offerIds = items.map(
                                        (item) => item.offer.id
                                    );

                                    const uniqueIds = list.offers.filter((id) =>
                                        offerIds.includes(id)
                                    );

                                    if (uniqueIds.length > 0) {
                                        this.updateStockByPusher(uniqueIds)
                                            .pipe(take(1))
                                            .subscribe();
                                    }
                                });
                        }
                    }
                )
            )
            .subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get items$(): Observable<IBasket[]> {
        return this._items.asObservable();
    }

    get sessionHash$(): Observable<string> {
        return this._sessionHash.asObservable();
    }

    get sessionExpire$(): Observable<string> {
        return this._sessionExpire.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Monitors the expiration time of the given basket item.
     * Sets up a timer to show a warning modal when the item is about to expire,
     * and removes the item when it expires.
     */
    private _monitorItem(item: IBasket): void {
        if (item.basket.expire_at !== null) {
            const now = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
            const expirationModal = moment(
                moment.utc(item.basket.expire_at).subtract(3, 'minutes')
            );
            const expirationDelete = moment.utc(item.basket.expire_at);
            const duration = expirationDelete.diff(now);

            if (duration <= 0) {
                this._removeItemByTimer(item.basket.id);
                return;
            }

            this._expepharmaTimerService.setTimer(
                `${item.basket.id}-modal`,
                expirationModal,
                () => {
                    if (!item.modalAlreadyOpened) {
                        this._matDialog.open(ModalWarningComponent, {
                            data: item,
                            width: '400px',
                            disableClose: true,
                        });
                    }
                }
            );

            this._expepharmaTimerService.setTimer(
                `${item.basket.id}-delete`,
                expirationDelete,
                () => {
                    this._removeItemByTimer(item.basket.id);
                }
            );
        }
    }

    /**
     * Removes the basket item with the given ID by subscribing
     * to the items$ observable, taking the first emitted value,
     * and passing it to _removeItemByTimerData to perform
     * the actual removal.
     */
    private _removeItemByTimer(id: number): void {
        this.items$.pipe(take(1)).subscribe((items) => {
            this._removeItemByTimerData(items, id);
            this._expepharmaTimerService.clearTimer(`${id}-modal`);
            this._expepharmaTimerService.clearTimer(`${id}-delete`);
        });
    }

    /**
     * Removes the basket item with the given ID from the items array.
     * Clears the timer for monitoring that item if one exists.
     */
    private _removeItemByTimerData(items: IBasket[], id: number): void {
        const updatedItems = items.filter((item) => item.basket.id !== id);
        this._items.next(updatedItems);
        this._expepharmaTimerService.clearTimer(`${id}-modal`);
        this._expepharmaTimerService.clearTimer(`${id}-delete`);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Gets all basket items from the API, decrypts the response,
     * sets the items in the service, and starts monitoring each
     * item's expiration.
     *
     * @returns An observable containing the array of basket items.
     */
    getAll(): Observable<IBasket[]> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/pharma/basket')
            .pipe(
                map((basket) => {
                    const values: IBasket[] = AuthUtils.fullAesDecode(basket);
                    this._items.next(values);
                    values.forEach((item) => this._monitorItem(item));
                    return values;
                })
            );
    }

    /**
     * Gets the current session basket from the API as an encrypted string,
     * decrypts it, updates the sessionHash subject with the decrypted hash,
     * and returns the decrypted hash.
     */
    getSessionBasket(): Observable<string> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/pharma/session-basket')
            .pipe(
                map((basket) => {
                    const values: string = AuthUtils.fullAesDecode(basket);
                    this._sessionHash.next(values);
                    return values;
                })
            );
    }

    /**
     * Adds a basket item by making a POST request to the basket API.
     * Updates the local basket items array with the response.
     * Starts monitoring the expiration of the newly added item.
     */
    addToBasket(basket: IBasketPayload): Observable<IBasketResponse> {
        return this.items$.pipe(
            take(1),
            switchMap((items) =>
                this._httpClient
                    .post<string>(environment.apiUrl + '/v1/pharma/basket', {
                        basket: AuthUtils.fullAesEncode(basket),
                    })
                    .pipe(
                        map((response: string) => {
                            const values: IBasketResponse =
                                AuthUtils.fullAesDecode(response);

                            console.log('values', values);

                            if (values.success === false) {
                                this._alertService.liveAlert({
                                    appearance: 'soft',
                                    dismissed: false,
                                    showIcon: true,
                                    type: 'info',
                                    position: 'bottom-center',
                                    message: values.message,
                                    clearTime: 5000,
                                });
                            } else {
                                const index = items.findIndex(
                                    (e) => e.offer.id === values.data.offer.id
                                );
                                if (index === -1) {
                                    this._items.next([...items, values.data]);
                                    this._monitorItem(values.data);
                                } else {
                                    items[index] = values.data;
                                    this._items.next(items);

                                    this._expepharmaTimerService.clearTimer(
                                        `${values.data.basket.id}-modal`
                                    );
                                    this._expepharmaTimerService.clearTimer(
                                        `${values.data.basket.id}-delete`
                                    );

                                    this._monitorItem(values.data);
                                }
                            }

                            return values;
                        })
                    )
            )
        );
    }

    /**
     * Updates the modalAlreadyOpened status for the basket item with the given ID.
     * Finds the matching basket item in the cached items array, sets modalAlreadyOpened
     * to true, and updates the cached items array.
     *
     * @param basketId - The ID of the basket item to update.
     * @returns An Observable containing the updated cached basket items array.
     */
    updateModalStatus(basketId: number): Observable<IBasket[]> {
        return this.items$.pipe(
            take(1),
            map((items) => {
                const index = items.findIndex((e) => e.basket.id === basketId);
                if (index !== -1) {
                    items[index].modalAlreadyOpened = true;
                    this._items.next(items);
                }
                return items;
            })
        );
    }

    /**
     * Removes an item from the basket by offer ID and product ID.
     *
     * @param offer_id - The offer ID of the item to remove.
     * @param product_id - The product ID of the item to remove.
     * @param hash - The hash for the basket.
     * @returns An Observable containing the response from removing the item.
     */
    removeFromBasket(
        offer_id: number,
        product_id: number,
        hash: string
    ): Observable<IBasketResponse> {
        return this.items$.pipe(
            take(1),
            switchMap((items) =>
                this._httpClient
                    .delete<string>(
                        environment.apiUrl + '/v1/pharma/basket/' + hash,
                        {
                            params: {
                                payload: AuthUtils.fullAesEncode({
                                    offer_id,
                                    product_id,
                                }),
                            },
                        }
                    )
                    .pipe(
                        map((response: string) => {
                            const values: IBasketResponse =
                                AuthUtils.fullAesDecode(response);
                            if (values.success === true) {
                                const index = items.findIndex(
                                    (e) => e.offer.id === offer_id
                                );

                                if (index !== -1) {
                                    this._removeItemByTimerData(
                                        items,
                                        items[index].basket.id
                                    );

                                    const newItems = items.filter(
                                        (e) =>
                                            e.basket.id !==
                                            items[index].basket.id
                                    );
                                    this._items.next(newItems);
                                }
                            }
                            return values;
                        })
                    )
            )
        );
    }

    /**
     * Removes an item from the basket by id.
     * Filters the items array to remove the item with the matching id.
     * @param idBasket - The id of the basket item to remove
     * @returns An observable containing the updated items array
     */
    removeItem(idBasket: number): Observable<IBasket[]> {
        return this.items$.pipe(
            take(1),
            tap((items) => {
                const list = items.filter((e) => e.basket.id !== idBasket);
                this._items.next(list);
            })
        );
    }

    /**
     * Updates the deadline for an item in the basket.
     *
     * @param offer_id - The offer ID for the item to update.
     * @param product_id - The product ID for the item to update.
     * @param hash - The basket hash.
     * @param error_message - Error message to show if item not found.
     * @returns An Observable containing the response from updating the deadline.
     */
    updateDeadlineFromBasket(
        offer_id: number,
        product_id: number,
        hash: string,
        error_message: string
    ): Observable<IBasketResponse> {
        return this.items$.pipe(
            take(1),
            switchMap((items) => {
                const itemExists = items.some(
                    (item) => item.offer.id === offer_id
                );
                if (!itemExists) {
                    this._alertService.liveAlert({
                        appearance: 'soft',
                        dismissed: false,
                        showIcon: true,
                        type: 'warning',
                        position: 'bottom-center',
                        message: error_message,
                        clearTime: 5000,
                    });
                    return of(null);
                } else {
                    return this._httpClient
                        .put<string>(
                            environment.apiUrl + '/v1/pharma/basket/' + hash,
                            {
                                payload: AuthUtils.fullAesEncode({
                                    offer_id,
                                    product_id,
                                }),
                            }
                        )
                        .pipe(
                            map((response: string) => {
                                const values: IBasketResponse =
                                    AuthUtils.fullAesDecode(response);
                                if (values.success === true) {
                                    const index = items.findIndex(
                                        (e) => e.offer.id === offer_id
                                    );

                                    if (index !== -1) {
                                        this._removeItemByTimerData(
                                            items,
                                            items[index].basket.id
                                        );

                                        items[index] = {
                                            ...values.data,
                                            modalAlreadyOpened: false,
                                        };

                                        this._monitorItem(values.data);

                                        this._items.next(items);
                                    }
                                }

                                return values;
                            })
                        );
                }
            })
        );
    }

    /**
     * Generates a payment checkout with Mollie for the given amount.
     *
     * Gets the current basket items, then makes a POST request to the environment.apiUrl + '/v1/pharma/checkout/mollie'
     * endpoint to generate a Mollie payment checkout for the given amount.
     *
     * The basket items and amount are encrypted before sending. The response is decrypted
     * and returned as an `IBasketResponse`.
     *
     * @param amount - The checkout amount to generate the payment for.
     * @returns Observable<IBasketResponse> - The API response with the generated checkout data.
     */
    generatePaymentCheckoutMollie(
        amount: number,
        hash: string,
        type: EnumBasketType,
        formData: any
    ): Observable<IBasketSessionPaymentResponse> {
        return this.items$.pipe(
            take(1),
            switchMap((items) =>
                this._httpClient
                    .post<string>(
                        environment.apiUrl + '/v1/pharma/checkout/mollie',
                        {
                            payload: AuthUtils.fullAesEncode({
                                amount: amount.toFixed(2),
                                hash: hash,
                                type: type,
                                data: formData,
                            }),
                        }
                    )
                    .pipe(
                        map((response: string) => {
                            const values: IBasketSessionPaymentResponse =
                                AuthUtils.fullAesDecode(response);

                            return values;
                        })
                    )
            )
        );
    }

    generatePaymentCheckout(
        paymentType: EnumPharmaPaymentType,
        amount: number,
        hash: string,
        type: EnumBasketType,
        formData: any
    ): Observable<IBasketSessionPaymentResponse> {
        return this.items$.pipe(
            take(1),
            switchMap((items) =>
                this._httpClient
                    .post<string>(
                        environment.apiUrl +
                            '/v1/pharma/checkout/' +
                            paymentType,
                        {
                            payload: AuthUtils.fullAesEncode({
                                amount: amount.toFixed(2),
                                hash: hash,
                                type: type,
                                data: formData,
                            }),
                        }
                    )
                    .pipe(
                        map((response: string) => {
                            const values: IBasketSessionPaymentResponse =
                                AuthUtils.fullAesDecode(response);

                            return values;
                        })
                    )
            )
        );
    }

    /**
     * Sets a sealed checkout session for the current basket items.
     *
     * Gets the current basket items, makes a POST request to create a sealed
     * session, and saves the session hash/expiry. Clears any timers on the
     * basket items.
     *
     * @returns Observable<boolean> - Whether the sealed session was successfully created.
     */
    setSealedSessionCheckout(list: IListSealedItem[]): Observable<boolean> {
        return this.items$.pipe(
            take(1),
            switchMap((items) =>
                this._httpClient
                    .post<string>(
                        environment.apiUrl + '/v1/pharma/session-basket',
                        {
                            payload: AuthUtils.fullAesEncode(list),
                        }
                    )
                    .pipe(
                        map((response: string) => {
                            const values: IBasketSessionCreateResponse =
                                AuthUtils.fullAesDecode(response);
                            if (values.success === true) {
                                this._sessionHash.next(values.data.hash);
                                this._sessionExpire.next(values.data.expire_at);
                                items.forEach((e) => {
                                    this._expepharmaTimerService.clearTimer(
                                        `${e.basket.id}-modal`
                                    );
                                    this._expepharmaTimerService.clearTimer(
                                        `${e.basket.id}-delete`
                                    );
                                });
                            }
                            return values.success;
                        })
                    )
            )
        );
    }

    /**
     * Removes the sealed checkout session for the current basket.
     *
     * Makes a DELETE request to remove the sealed session.
     * Clears the stored session hash/expiry.
     * Updates the basket items from the response.
     *
     * @returns Observable<boolean> - Whether the session was successfully removed.
     */
    removeSealedSessionCheckout(hash: string): Observable<boolean> {
        return this._httpClient
            .delete<string>(
                environment.apiUrl + '/v1/pharma/session-basket/' + hash,
                {}
            )
            .pipe(
                map((response: string) => {
                    const values: IBasketSessionDeleteResponse =
                        AuthUtils.fullAesDecode(response);
                    if (values.success === true) {
                        this._sessionHash.next(null);
                        this._sessionExpire.next(null);
                        this._items.next(values.data);
                        values.data.forEach((item) => this._monitorItem(item));
                    }
                    return values.success;
                })
            );
    }

    clearBasket(type?: EnumBasketType): Observable<IBasket[]> {
        return this.items$.pipe(
            take(1),
            tap((items) => {
                if (type) {
                    const data = items.filter((e) => e.basket.type !== type);
                    this._items.next(data);
                } else {
                    this._items.next([]);
                }

                this._sessionHash.next(null);
            })
        );
    }

    updateStockByPusher(listId: number[]): Observable<IBasket[]> {
        return this.items$.pipe(
            take(1),
            switchMap((items: IBasket[]) =>
                this._httpClient
                    .get<string>(
                        environment.apiUrl + '/v1/pharma/offers-realtime',
                        {
                            params: {
                                filters: AuthUtils.fullAesEncode({
                                    list: listId,
                                }),
                            },
                        }
                    )
                    .pipe(
                        map((result: string) => {
                            const values: IHttpPharmaOfferStockRealtimeUpdated =
                                AuthUtils.fullAesDecode(result);
                            if (values.success === true) {
                                values.data.forEach((element) => {
                                    const index = items.findIndex(
                                        (e) => e.offer.id === element.offer_id
                                    );
                                    if (index > -1) {
                                        items[index] = {
                                            ...items[index],
                                            offer: {
                                                ...items[index].offer,
                                                stocks: element.stocks,
                                            },
                                        };
                                    }
                                });
                            }
                            this._items.next(items);
                            return items;
                        })
                    )
            )
        );
    }

    getSealed(): Observable<IListSealedItem[]> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/pharma/session-sealed', {})
            .pipe(
                map((response: string) => {
                    const values: IListSealedItem[] =
                        AuthUtils.fullAesDecode(response);

                    return values;
                })
            );
    }
}

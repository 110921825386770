import { IPharmaOfferFull } from 'app/core/pharma-offer/pharma-offer.types';
// import { User } from 'app/core/user/user.types';
import { IUser } from 'app/modules/admin/common/settings/users/users.types';
export enum EnumBasketType {
    CLASSIC = 'classic',
    THRESHOLD = 'threshold',
}

export interface IBasket {
    qty: number;
    offer: IPharmaOfferFull;
    type: EnumBasketType;
    price: number;
    basket: IBasketDetails;
    modalAlreadyOpened?: boolean;
}

export interface IBasketDetails {
    id: number;
    user_id: number;
    offer_id: number;
    type: EnumBasketType;
    product_id: number;
    qty: number;
    expire_at: string;
    // "expire_at": "2024-02-25 13:34:49",
}

export interface IBasketResponse {
    success: boolean;
    data: IBasket;
    message: string;
}

export interface IBasketGroupUser {
    user: IUser;
    details: IBasket[];
}

export interface IBasketSessionCreateResponse {
    success: boolean;
    data: {
        hash: string;
        expire_at: string;
    };
    message: string;
}

export interface IBasketSessionDeleteResponse {
    success: boolean;
    data: IBasket[];
    message: string;
}

export interface IBasketSplitVat {
    rate: number;
    amount: number;
    details: any[];
}

export interface ICartonSize {
    id: string;
    width: number;
    height: number;
    depth: number;
    maxWeight: number;
}

export interface ICarton {
    cartonSizeId: string;
    width: number;
    height: number;
    depth: number;
    weight: number;
    volumeUsed: number;
    insuredValue: number;
    products: ICartonProduct[];
}

export interface ICartonProduct {
    id: number;
    label: string;
    qty: number;
    package: number;
    weight: number;
    volume: number;
    value: number;
}

export interface IPackingCosting {
    user_id: number;
    user?: IUser;
    packages: ICarton[];
}

export enum EnumPharmaPaymentType {
    BRIDGE = 'bridge',
    MOLLIE = 'mollie',
    MANUAL = 'manual',
    BP = 'bp',
    EXTERNAL = 'external',
}

export interface IBasketSessionPaymentResponse {
    success: boolean;
    data: string;
    message: string;
}

export interface IBasketPayload {
    product_id: number;
    hash: string;
    qty: number;
    type: EnumBasketType;
    is_update?: boolean;
}

export interface IListSealedItem {
    offer_id: number;
    product_id: number;
}
